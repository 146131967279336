import { Button, Progress } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import questions from "../lib/questions";
import categories, { interpretationOfTongues } from "../lib/categories";
import { StarIcon } from "@heroicons/react/24/solid";


function Results() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [expandResults, setExpandResults] = useState(false);

  useEffect(() => {
    const savedAnswers = localStorage.getItem("answers");
    if (savedAnswers) {
      const previousAnswers = JSON.parse(savedAnswers);
      setAnswers(previousAnswers);
      setQuestionIndex(Object.keys(previousAnswers).length);
    } else {
      setAnswers({});
    }
  }, [])

  const debug = useMemo(() => {
    return searchParams.get("debug") === "true";
  }, [searchParams])

  const resultSet = useMemo(() => {
    const result = {};

    for (let i = 1; i <= questions.length; i++) {
      if (answers[i]) {
        const answer = answers[i];
        if (!result[i % 24]) {
          result[i % 24] = 0;
        }
        result[i % 24] = result[[i % 24]] + answer;
      }
    }

    const entries = Object.entries(result);

    // Sort the entries by value in descending order
    entries.sort((a, b) => b[1] - a[1]);

    return entries.map(key => [Object.keys(categories)[key[0]], key[1]]);
  }, [answers]);

  const firstFive = useMemo(() => resultSet.slice(0, 5), [resultSet]);
  const everythingElse = useMemo(() => resultSet.slice(5, -1), [resultSet]);

  return (
    <div className="m-auto flex flex-col w-full sm:max-w-[640px] gap-2 px-8">
      <div className="pt-8 pb-4 text-center font-semibold text-2xl">
        Spiritual Gifts
      </div>
      <Progress percent={parseInt((parseFloat(questionIndex) / (questions.length - 1)) * 100) } />
      <div>Results:</div>
      { firstFive.map(([category, score], index) => (
          <div key={category}>
            <div className="flex justify-between">
              <div className="flex flex-grow font-bold">
                <span className="mr-2">{index + 1}.</span>
                <div className="flex items-center gap-1">
                  {index < 3 ? <StarIcon className="w-4 h-4 text-yellow-400" /> : null}
                  {category}
                </div>
              </div>
              <div className={`flex text-xs items-center rounded-md ${index < 3 ? 'bg-yellow-200 border-2 border-yellow-300' : 'bg-gray-200'} px-2`}>{score}</div>
            </div>
            <div className="my-2 bg-gray-100 p-4 rounded-md text-sm">
              <span className="block font-bold">{categories[category].thrust}</span>
              {categories[category].description}
              {category === "Interpretation of Tongues" && (
                <div className="mt-2">
                  <span className="block font-bold">{interpretationOfTongues.thrust}</span>
                  <span className="block font-bold"></span>
                  {interpretationOfTongues.description}
                </div>
              )}
            </div>
          </div>
        ))
      }
      {expandResults && everythingElse.map(([category, score], index) => (
          <div key={category}>
            <div className="flex justify-between">
              <div className="flex flex-grow font-bold">
                <span className="mr-2">{index + 6}.</span>
                <div className="flex items-center gap-1">
                  {category}
                </div>
              </div>
              <div className="flex text-xs items-center rounded-md bg-gray-200 px-2">{score}</div>
            </div>
            <div className="my-2 bg-gray-100 p-4 rounded-md">
              <span className="block font-bold">{categories[category].thrust}</span>
              {categories[category].description}
            </div>
          </div>
        ))
      }
      <div className="text-sm text-center underline cursor-pointer" onClick={() => setExpandResults(!expandResults)}>
        {expandResults ? "Only show my top 5 gifts" : "Show all the other gifts"}
      </div>
      <div>
        { questionIndex > 0 && <Button type="primary" className="w-full mt-4 text-lg h-14 font-lora" onClick={() => {
          setQuestionIndex(questions.length - 1);
          setTimeout(() => {
            navigate("/discover", { state: { questionIndex }, replace: true });
          }, 100);
        }}>
          I want to make changes
        </Button>}
      </div>
      <div
        className="text-center mt-4"
        onClick={() => {
          if (window.confirm('This will clear all your saved responses, are you sure?')) {
            localStorage.removeItem("answers");
            setTimeout(() => {
              navigate("/discover", { replace: true });
            }, 100)
          }
        }
      }>Start over again</div>
      {debug && <pre>
        {JSON.stringify(answers, null, 2)}
      </pre>}
    </div>
  );
}

export default Results;