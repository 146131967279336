const categories = {
  "Helps": {
    description: "The gifts of helps refers to the capacity to unselfishly meet the needs of others through very practical means.",
    thrust: "The attitude and ability to aid others in practical ways",
  },
  "Leadership": {
    description: "A person operating with a leadership gift demonstrates the capacity to exercise influence over a group so as to lead it toward a goal or purpose with a particular emphasis on the capacity to make decisions and keep the group operating together.",
    thrust: "Influencing others toward vision"
  },
  "Hospitality": {
    description: `The gifts of hospitalities Biblical concept often used with the terms "guest," "stranger," and "sojourner." It is useful to limit the meaning of "hospitality" to those outside one's normal circle of friends, as is implied in the literal meaning of the Greek word meaning "love of strangers."`,
    thrust: "Love of strangers guest traveler"
  },
  "Service": {
    description: "A person with the gift of service is often patient and others-centered. They see needs and finds joy in meeting them. They show love through action more than words and they attach value to even menial tasks.",
    thrust: "Looking for needs that others have"
  },
  "Administration": {
    description: "The gifts of administration involves a capacity to manage details of service functions so as to support and free other leaders to prioritize their efforts.",
    thrust: "Supportive organizational abilities"
  },
  "Discernment": {
    description: "The discernment gift refers to the ability given by God to perceive issues in terms of spiritual truth and to know the fundamental source of the issues and to give judgment concerning those issues; this includes the recognition of the spiritual forces operating in the issue.",
    thrust: "A sensitivity to truth its source"
  },
  "Faith": {
    description: "The gift of faith refers to the unusual capacity of a person to recognize in a given situation that God intends to do something and to trust God for it until He brings it to pass.",
    thrust: "A trusting response to a challenge from God",
  },
  "Music": {
    description: "The special gift whereby the Spirit enables this person to praise God through music in such a way as to enhance the worship experience for others.",
    thrust: "Wanting others to worship God through song"
  },
  "Tongues (and Interpretation of Tongues)": {
    description: "The gift of tongues refers to a spontaneous utterance of a word from God in unknown words (to the individual giving the word) to a group of people.",
    thrust: "Speaking a message in an unknown tongue"
  },
  "Miracles": {
    description: "The workings of powers, gift of miracles, refers to the releasing of God's supernatural power so that the miraculous intervention of God is perceived and God receives recognition for the supernatural intervention.",
    thrust: "The releasing of God's power to give authenticity"
  },
  "Craftsmanship": {
    description: "A person who is enabled to creatively design and/or construct items to be used for ministry.",
    thrust: "Working with your hands"
  },
  "Healing": {
    description: "The gift of healing refers to the supernatural releasing of healing power for curing all types of illnesses.",
    thrust: "Releasing God's power to heal"
  },
  "Giving": {
    description: "The gift of giving refers to the capacity to give liberally to meet the needs of others and yet to do so with a purity of motive which senses that the giving is a simple sharing of what God has given to you.",
    thrust: "A sensitivity to God to channel his resources to others"
  },
  "Mercy": {
    description: "The gift of mercy refers to the capacity to both feel sympathy for those in need (especially the suffering) and to manifest this sympathy in some practical helpful way with a cheerful spirit so as to encourage and help those in need.",
    thrust: "The empathetic care for those who are hurting"
  },
  "Wisdom": {
    description: "The word of wisdom gift refers to the capacity to know the mind of the Spirit in a given situation and to communicate clearly the situation, facts, truth or application of the facts and truth to meet the need of the situation.",
    thrust: "Applying revelatory information"
  },
  "Knowledge": {
    description: "The word of knowledge gift refers to the capacity or sensitivity of a person to supernaturally perceive revealed knowledge from God which otherwise could not or would not be known and apply it to a situation.",
    thrust: "Getting revelatory information"
  },
  "Exhortation": {
    description: "The gift of exhortation is the capacity to urge people to action in terms of applying Biblical truths, or to encourage people generally with Biblical truths, or to comfort people through the application of Biblical truth to their needs.",
    thrust: "To apply biblical truth"
  },
  "Teaching": {
    description: "A person who has the gift of teaching is one who has the ability to instruct, explain, or expose Biblical truth in such a way as to cause believers to understand the Biblical truth.",
    thrust: "To clarify truth"
  },
  "Pastor/Shepherd": {
    description: "The pastoral gift is the capacity to exercise concern and care for members of a group so as to encourage them in their growth in Christ which involves modeling maturity, protecting them from error and disseminating truth.",
    thrust: "Caring for the growth of followers"
  },
  "Apostleship": {
    description: "The gift of apostleship refers to a special leadership capacity to move with authority from God to create new ministry structures (churches and para-church) to meet needs and to develop and appoint leadership in these structures.",
    thrust: "Creating new ministry"
  },
  "Missionary": {
    description: `The special ability that God gives to certain members of the Body of Christ to minister whatever other spiritual gifts thy have in a culture other than their own.
    Those with this gift find it easy or exciting to adjust to a different culture or community. Missionaries find great joy working with minorities, people of other countries, or those with other distinct cultural differences. Those with this gift have a stronger-than-average desire to be a part of the fulfillment of the Great Commission around the world.`,
    thrust: "Sharing the gospel with the nations"
  },
  "Prophecy": {
    description: "A person operating with the gift of prophecy has the capacity to deliver truth (in a public way) either of a predictive nature or as a situational word from God in order to correct by exhorting, edifying or consoling believers and to convince non-believers of God's truth.",
    thrust: "To provide correction or perspective on a situation"
  },
  "Evangelism": {
    description: "The gift of evangelism in general refers to the capacity to challenge people through various communicative methods (persuasion) to receive the Gospel of salvation in Christ so as to see them respond by taking initial steps in Christian discipleship.",
    thrust: "Introducing others to the gospel"
  },
  "Intercession": {
    description: "Those with this gift often feel compelled to pray for specific requests when they are made known. Gifted intercessors have a much greater occurrence of specific answers to prayer than most Christians. Intercessors often have an acute understanding that prayer is genuinely a conversation with God, and when left alone, find themselves engaged in prayer. Many intercessors experience times when God moves them to pray for situations when they are not entirely sure what they are praying for. To those who get to know intercessors well, prayer becomes contagious. Those with this gift should help others learn how to pray more effectively.",
    thrust: "Asking God to intervene for specific needs"
  },
};

export const interpretationOfTongues = {
  description: "The gift of interpretation of tongues refers to the ability to spontaneously respond to a giving of an authoritative message in tongues by interpreting the words and clearly communicating the message given.",
  thrust: "Interpreting a message given in tongues"
}

export default categories;