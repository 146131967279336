const questions = [
  "I enjoy working behind the scenes, taking care of little details.",
  "I usually step forward and assume a leadership role in a group where none exists.",
  "I have the ability to recognize a need and to get the job done, no matter how small the task.",
  "I have the ability to organize ideas, people, and projects and to reach a specific goal.",
  "People often say I have good spiritual judgement.",
  "I am very confident of achieving great things for the glory of God.",
  "I am asked to sing or play a musical instrument at church functions.",
  "God has used me to communicate the Gospel in a language unknown to me.",
  "Through my prayers, God has made the impossible possible.",
  "I have an ability to use my hands in a creative way to design and build things.",
  "I have seen my prayers heal people.",
  "I enjoy giving money to those in serious financial need.",
  "I enjoy ministering to people in hospitals, prisons, or rest homes to comfort them.",
  "I often have insights that offer practical solutions to difficult problems.",
  "I have often understood issues or problems in the church and have seen answers when others didn’t.",
  "I enjoy encouraging and giving counsel to those who are discouraged.",
  "I have an ability to thoroughly study a passage of scripture and to share it with others.",
  "When in a group, I tend to notice and approach those who are alone, to help them feel part of the group.",
  "I presently have the responsibility for the spiritual growth of one or more young believers.",
  "Other people respect me as an authority in spiritual matters.",
  "I have an ability to learn foreign languages.",
  "God often reveals to me the direction He desires the body of Christ to move in.",
  "I enjoy developing relationships with Non-Christians, especially with hopes of telling them about Jesus.",
  "Whenever I hear reports on the news or in conversations about needy situations, I am burdened to pray.",
  "I would like to assist pastors or other leaders so they will have more time to accomplish their essential and priority ministries.",
  'When I ask people to help me accomplish an important ministry for the church, they usually say, "Yes."',
  "I enjoy entertaining guests and making them feel at home when they visit.",
  "I take the initiative to serve and enjoy serving others, no matter how small the task.",
  "I am a very organized person who sets goals, makes plans, and achieves the goals.",
  "I am a good judge of character and can spot a spiritual phony.",
  "I often step out and start projects that other people won't attempt, and the projects are usually successful.",
  "I believe I could make a positive difference on the music or worship team.",
  "Praying in tongues is personally meaningful to me in my prayer life.",
  "God has used me to make things happen which were far beyond human means.",
  "I enjoy doing things like woodworking, crocheting, sewing, metal work, stained glass, etc.",
  "I often pray for those who are physically and emotionally ill, for God to heal them.",
  "I joyfully give money to the church well above my tithe.",
  "I feel compassion for people who are hurting and lonely and like to spend considerable time with them to cheer them up.",
  "God has enabled me to choose correctly between several complex options in an important decision, when no one else knew what to do.",
  "I enjoy studying difficult questions about God's Word, and I am able to find answers more easily and more quickly than others.",
  "I'm very fulfilled when I encourage others, especially if it's about their spiritual growth.",
  "When a question arises from a difficult Bible passage, I am motivated to research the answer.",
  "I enjoy being involved in people's lives and helping them grow spiritually.",
  "I would be willing and excited to start a new church.",
  "I can adapt easily to cultures, languages, and lifestyles and would like to use my adaptability to minister in foreign countries.",
  "I will always speak up for Christian principles with conviction, even when what I say isn't popular.",
  "I find it easy to invite people to accept Jesus as their Savior.",
  "I have a passion to pray for the significant issues of God's kingdom and His will for Christians.",
  "I enjoy relieving others of routine tasks so they can get important projects done.",
  "I can guide and motivate a group of people toward achieving a specific goal.",
  "I enjoy meeting new people and introducing them to others in the group.",
  "I am very dependable for getting things done on time, and I don't need much praise or thanks.",
  "I easily delegate significant responsibilities to other people.",
  "I am able to distinguish between right and wrong in complex spiritual matters that other people can't seem to figure out.",
  "I trust in God's faithfulness for a bright future, even when facing significant problems.",
  "I enjoy singing, and people say I have a good voice.",
  "I have been led by the Holy Spirit during prayer or worship and have begun to speak in tongues and/or interpret tongues.",
  "God has blessed my prayers so that supernatural results have come from otherwise impossible situations.",
  "I find satisfaction in meeting the needs of people by making something for them.",
  "God regularly speaks to me concerning people's illnesses so that I can pray for them.",
  "I wouldn't mind lowering my standard of living to give more to the church and others in need.",
  "I want to do whatever I can for the needy people around me, even if I have to give up something.",
  "People often seek my advice when they don't know what to do in a situation.",
  "I have an ability to gather information from several sources to discover the answer to a question or to learn more about a subject.",
  "I feel a need to challenge others to better themselves, especially in their spiritual growth, in an uplifting, not condemning, way.",
  "Others listen and enjoy my teaching of scriptures.",
  "I care about the spiritual welfare of people and do my best to guide them toward a Godly lifestyle.",
  "I am accepted as a spiritual authority in other parts of the country or world.",
  "I would like to present the gospel in a foreign language, in a country whose culture and lifestyle is different than my own.",
  "I feel a need to speak God's messages from the Bible so people will know what God expects of them.",
  "I would like to tell others how to become a Christian and offer them an invitation to receive Jesus into their life.",
  "Many of my prayers for others have been answered by the Lord.",
  "I enjoy helping others get their work done and don't need a lot of public recognition.",
  "People respect my opinion and follow my direction.",
  "I would like to use my home to get acquainted with newcomers and visitors to the church.",
  "I enjoy helping people in any type of need and feel a sense of satisfaction in meeting that need.",
  "I am comfortable making important decisions, even under pressure.",
  "People come to me for help in distinguishing between spiritual truth and error.",
  "I often exercise my faith through prayer, and God answers my prayers in powerful ways.",
  "I believe the Lord could use me in the choir to deliver a message through song.",
  "I have spoken in a language unknown to me that, when interpreted, brought a blessing to those who heard.",
  "God uses me to work miracles for the glory of His kingdom.",
  "People say I am gifted with my hands.",
  "People often seek me out to pray for their physical healing.",
  "When I give money to someone, I don't expect anything in return and often give anonymously.",
  "When I hear of people without jobs who can't pay their bills, I do what I can to help them.",
  "God enables me to make appropriate application of Biblical truth to practical situations.",
  "I can discover difficult Biblical truths and principles on my own, and I enjoy this.",
  "People find it easy to talk with me and respond well to my encouragement to become all they can for God.",
  "I am organized in my thinking and systematic in my approach to presenting Bible lessons to a group of people.",
  "I help Christians who have wandered away from the Lord find their way back to a growing relationship with Him and get involved in a local church.",
  "I would be excited to share the Gospel and form new groups of Christians in areas where there aren't many churches.",
  "I have no racial prejudice and have a sincere appreciation for people very different from myself.",
  "I find it relatively easy to apply Biblical promises to present-day situations, and I'm willing to confront, in love, if necessary.",
  "I have a strong desire to help Non-Christians find salvation through Jesus Christ.",
  "Prayer is my favorite ministry in the church, and I consistently spend a great deal of time at it.",
];

export default questions;