const en = {
  errorPage: {
    generic: {
      header: "Houston, we have a problem",
      body: "An unexpected error has occurred.",
      message:
        "We encountered a problem, kindly reload the page to try again. If this problem persists, kindly contact the staff.",
      actions: {
        menu: "Take me back home",
      },
    },
    404: {
      header: "Are you lost?",
      body: "The page you are looking for does not seem to exist.",
      action: 'Take me back home',
    },
  },
};

export default en;