import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();

  return (
    <div className="m-auto flex flex-col max-w-[540px] px-8">
      <div className="pt-8 pb-4 text-center font-semibold text-2xl">
        Spiritual Gifts
      </div>
      <div className="flex overflow-auto">
        Enjoy the process –– This is not a test! There are no right or wrong answers, only true statements about your experience and preference, according to the simple questions.
      </div>
      <div>
        <Button type="primary" className="w-full mt-4 text-xl h-12 font-bold font-lora" onClick={() => 
          navigate('/discover')
        }>
          Start test
        </Button>
      </div>
    </div>
  );
}

export default Index;