import { useMemo } from "react";

import { MapPinIcon, RocketLaunchIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { Link, useRouteError } from "react-router-dom";

import Button from "../components/Button";

export default function ErrorPage() {
  const error = useRouteError();

  const errorCode = useMemo(() => error.status, [error]);
  const errorMessage = useMemo(
    () => error.statusText || error.message,
    [error],
  );

  const { t } = useTranslation();

  const copyKeyPrefix = useMemo(
    () => (errorCode === 404 ? "errorPage.404" : "errorPage.generic"),
    [errorCode],
  );
  const CustomErrorIcon = useMemo(
    () => (errorCode === 404 ? MapPinIcon : RocketLaunchIcon),
    [errorCode],
  );

  const channelId = window._X_CHANNEL_ID;
  const channelLinkId = window._X_CHANNEL_LINK_ID;
  const entity = window._X_ENTITY;
  const menuRoute = [channelId, channelLinkId, entity]
    .filter((n) => n)
    .join("/");

  return (
    <>
      <div className="absolute inset-0 z-20"></div>
      <div
        id="error-page"
        className="absolute z-30 flex justify-center p-4 rounded-lg inset-3 sm:inset-6 bg-main"
      >
        <div className="w-full max-w-[800px] flex flex-col items-center m-auto">
          <CustomErrorIcon className="text-primary w-[48px] h-[48px] mb-3" />
          <div className="text-xl font-bold text-center">
            {t(`${copyKeyPrefix}.header`)}
          </div>
          <div className="mt-1 text-sm text-center">
            {t(`${copyKeyPrefix}.body`)}
          </div>
          {errorCode !== 404 && (
            <div className="p-2 mt-2 font-mono text-center text-red-500 bg-gray-200 rounded-md">
              {errorMessage ?? t("errorPage.generic.message")}
            </div>
          )}
          <Link to={`/${menuRoute}`} className="mt-3">
            <Button type="primary">
              {t("errorPage.404.action")}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
