import { Button, Progress, Radio } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import questions from "../lib/questions";

function Discover() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [answers, setAnswers] = useState({});
  const [response, setResponse] = useState(null);
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const [questionIndex, setQuestionIndex] = useState(state ? state.questionIndex : 0);
  const [redirectToResults, setRedirectToResults] = useState(false);

  useEffect(() => {
    if (answers[questionIndex]) {
      setResponse(answers[questionIndex]);
    }
  }, [questionIndex, answers]);

  useEffect(() => {
    if (Object.keys(answers).length > 0) {
      localStorage.setItem("answers", JSON.stringify(answers));
      if (redirectToResults) {
        setTimeout(() => {
          navigate("/results", { state: { answers }, replace: true });
        }, 100);
      }
    }
  }, [answers, redirectToResults, navigate])

  useEffect(() => {
    const savedAnswers = localStorage.getItem("answers");
    if (savedAnswers) {
      const previousAnswers = JSON.parse(savedAnswers);
      setAnswers(previousAnswers);
      if (!state) {
        setQuestionIndex(Object.keys(previousAnswers).length - 1);
      }
    } else {
      setAnswers({});
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function submitAnswer(fromButton = false) {
    if (response !== null && response !== undefined) {
      setAnswers((answers) => {
        return {
          ...answers,
          [questionIndex]: response,
        };
      });
      setResponse(null);
      if (questionIndex === questions.length -1) {
        setRedirectToResults(true);
      } else {
        setQuestionIndex(questionIndex + 1);
      }
    } else {
      if (!fromButton) {
        alert("Please select a response");
      }
    }
  }

  const debug = useMemo(() => {
    return searchParams.get("debug") === "true";
  }, [searchParams])

  return (
    <div className="m-auto flex flex-col w-full sm:max-w-[640px] gap-2 px-8">
      <div className="pt-8 pb-4 text-center font-semibold text-2xl">
        Spiritual Gifts
      </div>
      <Progress percent={parseInt((parseFloat(questionIndex) / (questions.length - 1)) * 100) } />
      {questionIndex}/{questions.length}
      <div className="font-bold">{`Discovery question #${questionIndex + 1}`}</div>
      <div className="min-h-[80px]">
        {questions[questionIndex]}
      </div>
      <div>Your response: {response}</div>
      <div className="w-full">
        <Radio.Group
          className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:columns-5 w-full"
          value={(response !== null && response !== undefined) ? (response + 1) : (answers[questionIndex] + 1)}
          onChange={(e) => {
            setResponse(e.target.value - 1);
          }}
        >
          <Radio.Button className="flex flex-grow flex-shrink-0 items-center justify-center text-center align-middle h-14 sm:text-sm text-md font-lora rounded-md sm:rounded-none" value={1}><div className="flex flex-grow w-full">Not at all</div></Radio.Button>
          <Radio.Button className="flex flex-grow flex-shrink-0 items-center justify-center text-center align-middle h-14 sm:text-sm text-md font-lora rounded-md sm:rounded-none" value={2}><div className="flex flex-grow w-full">Little</div></Radio.Button>
          <Radio.Button className="flex flex-grow flex-shrink-0 items-center justify-center text-center align-middle h-14 sm:text-sm text-md font-lora rounded-md sm:rounded-none" value={3}><div className="flex flex-grow w-full">Moderately</div></Radio.Button>
          <Radio.Button className="flex flex-grow flex-shrink-0 items-center justify-center text-center align-middle h-14 sm:text-sm text-md font-lora rounded-md sm:rounded-none" value={4}><div className="flex flex-grow w-full">Considerably</div></Radio.Button>
          <Radio.Button className="flex flex-grow flex-shrink-0 items-center justify-center text-center align-middle h-14 sm:text-sm text-md font-lora rounded-md sm:rounded-none" value={5}><div className="flex flex-grow w-full">Strongly</div></Radio.Button>
        </Radio.Group>
      </div>
      <div>
        <Button
          type="primary"
          className="w-full mt-4 text-lg h-14 font-lora"
          disabled={!(response + 1)}
          onClick={submitAnswer}
        >
          { questionIndex === questions.length - 1 ? "Finish" : "Next question" }
        </Button>
        { questionIndex > 0 && <Button type="primary" className="w-full mt-4 text-lg h-14 font-lora" onClick={() => {
          setQuestionIndex(questionIndex - 1);
        }}>
          Previous question
        </Button>}
      </div>
      <div
        className="text-center mt-4 cursor-pointer"
        onClick={() => {
          if (window.confirm('This will clear all your saved responses, are you sure?')) {
            localStorage.removeItem("answers");
            window.location.reload();
          }
        }
      }>Start over again</div>
      {debug && <pre>
        {JSON.stringify(answers, null, 2)}
      </pre>}
    </div>
  );
}

export default Discover;