import './App.css';
import { RouterProvider } from 'react-router-dom';

import router from "./router";

import "./lib/i18n.js";

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
